import windowGlobal from '../utils/window-global';
import { useContext } from 'react';
import ThemeType from '../common/theme-type';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import ThemeContext from '../components/theme-context';
import { THEME_STORAGE_KEY } from '../components/theme-provider';

interface IUseThemeContext {
  theme: ThemeType;
  switchTheme: () => void;
}

const storeSelectedTheme = (theme: ThemeType) => {
  if (!windowGlobal) return;
  windowGlobal.localStorage.setItem(THEME_STORAGE_KEY, theme);
};

const useThemeContext = (): IUseThemeContext => {
  const { theme, setTheme } = useContext(ThemeContext);

  const switchTheme = () => {
    if (!setTheme) return;

    const nextTheme = theme === ThemeType.LIGHT ? ThemeType.DARK : ThemeType.LIGHT;
    setTheme(nextTheme);
    storeSelectedTheme(nextTheme);

    trackCustomEvent({
      category: 'Switch theme',
      action: 'Click'
    });
  };

  return { theme, switchTheme };
};

export default useThemeContext;
