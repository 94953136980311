import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

type ImageProps = {
  name: string;
  alt?: string;
  style?: Record<string, string | number>;
};

interface INodeData {
  node: {
    fluid: FluidObject & {
      originalName: string;
    };
  };
}

interface IImageData {
  allImageSharp: {
    edges: INodeData[];
  };
}

const Image: React.FC<ImageProps> = ({ name, alt, style }) => {
  const data: IImageData = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            id
            fluid(maxWidth: 300) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  const fluidImage = useMemo(
    () => data.allImageSharp.edges.map(image => image.node.fluid).find(fluid => fluid.originalName === name),
    [data, name]
  );

  return <Img style={style} fluid={fluidImage ?? []} alt={alt} loading="eager" />;
};

export default Image;
