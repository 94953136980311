import React from 'react';
import useThemeContext from '../../hooks/use-theme-context';
import * as style from './theme-switch.module.scss';
import ThemeType from '../../common/theme-type';
import Image from '../image';

const ThemeSwitch: React.FC = () => {
  const { switchTheme, theme } = useThemeContext();

  const bulbIcon = theme === ThemeType.LIGHT ? 'bulb-icon-on.png' : 'bulb-icon-off.png';
  const guide = theme === ThemeType.LIGHT ? 'Too bright? Tap me.' : 'Too dark? Tap me for brighter idea.';

  return (
    <div role="button" tabIndex={0} className={style.switchButton} onClick={switchTheme}>
      <span className={style.guide}>{guide}</span>
      <span className={style.image}>
        <Image name={bulbIcon} alt="bulb icon for theme switch" />
      </span>
    </div>
  );
};

export default ThemeSwitch;
