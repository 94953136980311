import React from 'react';
import Image from '../image';
import * as style from './hero-image.module.scss';

type HeroImageProps = {
  size?: number;
};

const HeroImage: React.FC<HeroImageProps> = ({ size }) => (
  <div className={style.heroImage} style={{ width: `${size}rem` }}>
    <Image name="norbert-profil.jpg" style={{ left: '11px' }} alt="Norbert Bartos" />
  </div>
);

HeroImage.defaultProps = {
  size: 12
};

export default HeroImage;
