import React from 'react';
import * as style from './button.module.scss';

type ButtonProps = {
  caption: string;
  width?: string;
  height?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({ caption, height, width, type, onClick }: ButtonProps) => {
  const dimension = {
    minHeight: height,
    minWidth: width
  };

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} style={dimension} onClick={onClick} className={style.button}>
      {caption}
    </button>
  );
};

Button.defaultProps = {
  width: '100px',
  height: '40px',
  type: 'button'
};

export default Button;
